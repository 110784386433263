import { Layout } from '@loadsmart/miranda-react'
import { isEmpty } from 'lodash'

import UIAppliedFilters, { getLabel } from 'components/AppliedFilters'

function getAppliedFilters<TObj extends Record<string, unknown>>(
  filters: TObj
) {
  const applied = Object.entries(filters).filter(
    ([, values]) => !isEmpty(values)
  )

  return applied
}

type FilterValueFormatter<T> = {
  [K in keyof T]: (
    key: NonNullable<T[K]> extends Array<infer U> ? U : NonNullable<T[K]>
  ) => string
}

function getFiltersMergedLabel<TObj extends Record<string, unknown>>(
  filters: [keyof TObj, unknown][],
  valueFormatter: Partial<FilterValueFormatter<TObj>>
) {
  return filters.map(([filterKey, values]) => {
    const formatter = valueFormatter[filterKey]

    if (Array.isArray(values)) {
      return {
        key: filterKey,
        values: values.map((value) => ({
          value,
          label: formatter ? formatter(value) : value,
        })),
      }
    }

    const nonNullValues = values as NonNullable<
      TObj[typeof filterKey]
    > extends (infer U)[]
      ? U
      : NonNullable<TObj[keyof TObj]>

    return {
      key: filterKey,
      values: [
        {
          value: nonNullValues,
          label: formatter ? formatter(nonNullValues) : String(nonNullValues),
        },
      ],
    }
  })
}

interface AppliedFiltersProps<TObj extends Record<string, unknown>> {
  readonly values: TObj
  readonly onChange: (nextValues: Partial<TObj>) => void
  readonly valueFormatter: FilterValueFormatter<TObj>
  readonly labels: Record<keyof TObj, string>
}

export function AppliedFilters<TObj extends Record<string, unknown>>(
  props: AppliedFiltersProps<TObj>
) {
  const appliedFilters = getAppliedFilters(props.values)

  const filtersLabel = getFiltersMergedLabel(
    appliedFilters,
    props.valueFormatter
  )

  if (isEmpty(appliedFilters)) {
    return null
  }

  return (
    <Layout.Group gap="spacing-2" align="center">
      <UIAppliedFilters
        handleRemove={(removedFilter) => {
          props.onChange({ ...props.values, [removedFilter]: null })
        }}
        onClearFilters={() => props.onChange({})}
        appliedFilters={filtersLabel.map(({ key, values }) => ({
          value: key,
          label: `${props.labels[key]}: ${getLabel(
            values,
            (option) => option.label
          )}`,
        }))}
      />
    </Layout.Group>
  )
}
