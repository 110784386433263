import { isEmpty, uniq } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import { useValidateOrders } from 'orders/orders.hooks'
import type { ListOrder } from 'orders/types'
import { createSearchParams } from 'utils/searchParams'

import { useLoadOrderData } from '../common/useLoadOrderData'

export const SELECTED_ORDERS_QUERY_PARAM = 'selected'
export const ORDER_GROUPING_MULTIPLE_OPTION = 'multiple'
export const ORDER_GROUPING_SINGLE_OPTION = 'single'
export type OrderGroupingMode =
  | typeof ORDER_GROUPING_MULTIPLE_OPTION
  | typeof ORDER_GROUPING_SINGLE_OPTION

function sortOrdersByReferenceFirst(
  orders: ListOrder[],
  referenceOrderUuid: string
) {
  // The validation of the consolidation (for example, if all the orders have the same origin/destination)
  // is based on a fixed order as reference (the first one), so we need to show it always as the first on
  // the list of selected orders)
  let sortedList: ListOrder[] = []

  orders.forEach((order) => {
    if (order.uuid === referenceOrderUuid) {
      sortedList = [order, ...sortedList]
    } else {
      sortedList.push(order)
    }
  })

  return sortedList
}

export const useSelectedOrdersQueryParams = () => {
  const history = useHistory()
  const location = useLocation()

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search)
  }, [location.search])

  const selectedOrderUUIDs = useMemo(
    () => searchParams.getAll(SELECTED_ORDERS_QUERY_PARAM) || [],
    [searchParams]
  )

  const setSelectedOrders = useCallback(
    (newSelected: string[]) => {
      const newInit = new URLSearchParams(location.search)

      while (newInit.has(SELECTED_ORDERS_QUERY_PARAM)) {
        newInit.delete(SELECTED_ORDERS_QUERY_PARAM)
      }

      newSelected.forEach((orderUUID) => {
        newInit.append(SELECTED_ORDERS_QUERY_PARAM, orderUUID)
      })

      const newSearchParams = createSearchParams(newInit)

      history.push(`${history.location.pathname}?${newSearchParams.toString()}`)
    },
    [history, location.search]
  )

  const addSelectedOrders = useCallback(
    (newSelected: string[]) => {
      const newInit = new URLSearchParams(location.search)

      newSelected.forEach((orderUUID) => {
        newInit.append(SELECTED_ORDERS_QUERY_PARAM, orderUUID)
      })

      const newSearchParams = createSearchParams(newInit)

      history.push(`${history.location.pathname}?${newSearchParams.toString()}`)
    },
    [history, location.search]
  )

  const removeSelectedOrders = useCallback(
    (toBeRemoved: string | string[]) => {
      const newSelectedOrdersUUIDs = selectedOrderUUIDs.filter((uuid) => {
        if (Array.isArray(toBeRemoved)) {
          return !toBeRemoved.includes(uuid)
        }
        return toBeRemoved !== uuid
      })
      const newInit = new URLSearchParams(location.search)

      while (newInit.has(SELECTED_ORDERS_QUERY_PARAM)) {
        newInit.delete(SELECTED_ORDERS_QUERY_PARAM)
      }

      newSelectedOrdersUUIDs.forEach((orderUUID) => {
        newInit.append(SELECTED_ORDERS_QUERY_PARAM, orderUUID)
      })

      const newSearchParams = createSearchParams(newInit)

      history.push(`${history.location.pathname}?${newSearchParams.toString()}`)
    },
    [history, location.search, selectedOrderUUIDs]
  )

  return {
    addSelectedOrders,
    removeSelectedOrders,
    selectedOrderUUIDs,
    setSelectedOrders,
  }
}

const useSelectedOrders = () => {
  const { selectedOrderUUIDs, ...queryParams } = useSelectedOrdersQueryParams()
  const { isLoading, orders } = useLoadOrderData(selectedOrderUUIDs)
  const { data: validationResult, isLoading: isLoadingValidation } =
    useValidateOrders(selectedOrderUUIDs)

  const selectedOrders = useMemo(() => {
    if (validationResult?.uuid) {
      return sortOrdersByReferenceFirst(orders, validationResult.uuid)
    }

    return orders
  }, [orders, validationResult?.uuid])

  const { data: pickupFacilityDetails } = useFacilityDetailsV2(
    validationResult?.stops?.[0]?.facility_uuid,
    {
      enabled: validationResult?.stops?.[0]?.facility_uuid != null,
      refetchOnWindowFocus: false,
    }
  )
  const { data: deliveryFacilityDetails } = useFacilityDetailsV2(
    validationResult?.stops?.[1]?.facility_uuid,
    {
      enabled: validationResult?.stops?.[1]?.facility_uuid != null,
      refetchOnWindowFocus: false,
    }
  )

  const poNumbers = uniq(
    selectedOrders
      ?.map((order) => order.po_number)
      .filter((po_number) => !isEmpty(po_number))
  )
  const soNumbers = uniq(
    selectedOrders
      ?.map((order) => order.so_number)
      .filter((so_number) => !isEmpty(so_number))
  )
  const primaryRefs = uniq(
    selectedOrders
      ?.map((order) => order.primary_ref)
      .filter((primary_ref) => !isEmpty(primary_ref))
  )

  return {
    isLoading,
    isLoadingValidation,
    selectedOrders,
    selectedOrderUUIDs,
    validationResult,
    selectedOrdersPONumbers: poNumbers.join(', '),
    selectedOrdersSONumbers: soNumbers.join(', '),
    selectedOrdersPrimaryRefs: primaryRefs.join(', '),
    pickupFacilityDetails,
    deliveryFacilityDetails,
    ...queryParams,
  }
}

export default useSelectedOrders
