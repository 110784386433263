import { PageContent } from '@loadsmart/miranda-react'

import { WindowTitle } from '_shared_/components/WindowTitle'

import { ListFulfillmentsPageHeader } from './components/ListFulfillmentsPageHeader'

export function ListFulfillmentsPage() {
  return (
    <>
      <WindowTitle title="Fulfillments | ShipperGuide" />
      <PageContent>
        <ListFulfillmentsPageHeader />
      </PageContent>

      <PageContent.Body />
    </>
  )
}
