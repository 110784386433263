import { useMemo } from 'react'

import { useSettings } from 'contexts/settings'
import { useAccessorials, usePriceItemTypes } from 'hooks/useQuery'

function getAccessorialTagByValue({
  priceItemTypesEnabled,
  accessorials = [],
  priceItemTypes = [],
  value,
}: {
  priceItemTypesEnabled: boolean
  accessorials: Accessorial[] | undefined
  priceItemTypes: PriceItemType[] | undefined
  value: string
}): string {
  if (priceItemTypesEnabled) {
    const [uuid, stop] = value.split('_')
    const accessorialName =
      priceItemTypes.find((priceItemType) => priceItemType.uuid === uuid)
        ?.label ?? ''

    return (
      stop ? `${accessorialName} [${stop}]` : accessorialName
    ).toUpperCase()
  }
  const accessorial = accessorials.find((accs) => accs.code === value)
  const accessorialName = accessorial?.name ?? ''
  const stop = accessorial?.stop_type?.[0]

  return (stop ? `${accessorialName} [${stop}]` : accessorialName).toUpperCase()
}

export function useAccessorialsTagsByFormValues({
  values,
  mode,
  usage,
}: {
  values: {
    requested_accessorials: string[]
    accessorials: string[]
  }
  mode: TransportationModeCode
  usage: AccountingObjectType
}) {
  const {
    values: [priceItemTypesEnabled],
  } = useSettings(['flags.ENABLE_PRICE_ITEM_TYPES'])

  const { data: accessorials, isLoading: accessorialsLoading } =
    useAccessorials(mode, {
      refetchOnWindowFocus: false,
      enabled: !priceItemTypesEnabled,
    })
  const { data: priceItemTypes, isLoading: priceItemTypesLoading } =
    usePriceItemTypes({ mode, usage }, { enabled: !!priceItemTypesEnabled })

  const accessorialsTags = useMemo(
    () =>
      values[
        priceItemTypesEnabled ? 'requested_accessorials' : 'accessorials'
      ].map((value) =>
        getAccessorialTagByValue({
          priceItemTypesEnabled,
          accessorials,
          priceItemTypes,
          value,
        })
      ),
    [values, accessorials, priceItemTypes, priceItemTypesEnabled]
  )

  return {
    isLoading: priceItemTypesEnabled
      ? priceItemTypesLoading
      : accessorialsLoading,
    accessorialsTags,
  }
}
