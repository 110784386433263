import type { TagSize, TagVariant } from '@loadsmart/miranda-react'
import { Tag } from '@loadsmart/miranda-react'
import { getFulfillmentStatusLabel } from 'fulfillments/domain/Fulfillment'
import type {
  Fulfillment,
  FulfillmentStatus,
} from 'fulfillments/domain/Fulfillment'
import { get } from 'lodash'

const VARIANT_MAP: Record<FulfillmentStatus, TagVariant> = {
  pending: 'warning',
  planned: 'neutral',
}

export function FulfillmentStatusTag({
  fulfillment,
  size = 'small',
}: Readonly<{ fulfillment?: Fulfillment; size?: TagSize }>) {
  const status = get(fulfillment, 'status') as FulfillmentStatus

  if (!status) {
    return null
  }

  return (
    <Tag
      data-testid="fulfillment-status-tag"
      size={size}
      variant={VARIANT_MAP[status]}
    >
      {getFulfillmentStatusLabel(status)}
    </Tag>
  )
}
