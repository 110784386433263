import { get } from 'lodash'
import { useEffect } from 'react'

import { useFacilityDrawer } from '_shared_/hooks/useFacilityDrawer'
import { useContactsDrawer } from 'components/ContactsDrawer/useContactsDrawer'
import { useFacilityDetailsV2 } from 'components/FacilityDetails/useFacilityDetailsV2'
import {
  mapFacilityContactV1toV2,
  mapFacilityDetailsV1ToV2,
} from 'utils/adapters/facilityV1toV2'

import { useStopFormField } from './useStopFormField'

export const useStopFacility = () => {
  const { openCreateFacility, openEditFacility } = useFacilityDrawer()
  const { openContactsManager: openContactsDrawer } = useContactsDrawer()

  const { stop, index, dispatch } = useStopFormField({ name: '' })

  const facilityUUID = get(stop, 'facility.uuid')

  const facilityDetailsQuery = useFacilityDetailsV2(facilityUUID, {
    enabled: facilityUUID != null,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (facilityDetailsQuery.isSuccess) {
      const facility = facilityDetailsQuery.data

      const stopNotes = get(stop, 'notes', '')
      const facilityNotes = get(facility, 'notes.0.note', '')

      dispatch({
        type: 'SET_ITEM',
        payload: {
          index,
          changes: {
            facility: {
              ...get(stop, 'facility', {}),
              ...facility,
            },
            notes: stopNotes || facilityNotes,
          },
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we only care about the fetched data, if any
  }, [facilityDetailsQuery.isSuccess])

  const openContactsManager = () => {
    if (!facilityUUID) {
      return
    }

    openContactsDrawer({
      facilityUUID,
      /* istanbul ignore next -- this feature belongs to the tests in src/components/ContactsDrawer/__tests__/ContactsDrawer.test.tsx */
      onContactSaved: (contact) => {
        dispatch({
          type: 'SET_ITEM',
          payload: {
            index,
            changes: {
              contact: {
                ...mapFacilityContactV1toV2(contact),
                // we add the `_type` so the Select component can load the value correctly.
                // @ts-expect-error -- we currently have type misconfiguration in the app; this should be dealt with soon.
                _type: 'contact',
              },
            },
          },
        })
      },
    })
  }

  const openCreateFacilityDrawer = () => {
    openCreateFacility({
      onFacilitySaved: (facility) => {
        dispatch({
          type: 'SET_ITEM',
          payload: {
            index,
            changes: {
              /**
               * we add the `_type` so the Select component can load the value correctly.
               * We need this specifically in this order because, when a new facility is added,
               * the `FacilityDrawer` sets its type to `generic`.
               */
              facility: {
                ...mapFacilityDetailsV1ToV2(facility),
                // @ts-expect-error -- we need this so we can feed facility into our select component.
                _type: 'facility',
              },
              contact: null,
            },
          },
        })
      },
    })
  }

  const openEditFacilityDrawer = () => {
    if (!facilityUUID) {
      return
    }

    openEditFacility({
      facilityUUID,
      /* istanbul ignore next -- this feature belongs to the tests in src/components/FacilityDrawer/__tests__/index.test.tsx */
      onFacilitySaved: (facility) => {
        dispatch({
          type: 'SET_ITEM',
          payload: {
            index,
            changes: {
              facility: {
                ...mapFacilityDetailsV1ToV2(facility),
                /**
                 * we add the `_type` so the Select component can load the value correctly.
                 * We need this specifically in this order because, when a new facility is added,
                 * the `FacilityDrawer` sets its type to `generic`.
                 */
                // @ts-expect-error -- we need this so we can feed facility into our select component.
                _type: 'facility',
              },
            },
          },
        })
      },
    })
  }

  return {
    openCreateFacilityDrawer,
    openEditFacilityDrawer,
    openContactsManager,
  }
}
