import type { HandlingUnit } from 'components/HandlingUnitsManager/HandlingUnits.types'
import type { Measurable, PackageType } from 'components/ShippingItemsManager'
import { createTransientStop } from 'components/StopsManager'
import type { Stop } from 'components/StopsManager'
import type { Order, OrderItem } from 'orders/types'
import type { StopAppointment } from 'screens/Shipper/Shipments/Details/types'
import { createTransient } from 'utils/transient'
import type { TransientDeep } from 'utils/transient'

export type FulfillmentStatus = 'pending' | 'planned'

export const FULFILLMENT_STATUS_LABEL: Record<FulfillmentStatus, string> = {
  pending: 'Pending',
  planned: 'Planned',
}

export function getFulfillmentStatusLabel(status: FulfillmentStatus) {
  return FULFILLMENT_STATUS_LABEL[status]
}

export type BaseFulfillment = {
  customer_uuid?: string
  delivery_date: string
  delivery_facility_uuid: string
  delivery_instructions: string
  handling_units: HandlingUnit[]
  notes?: string
  pickup_facility_uuid: string
  pickup_instructions: string
  pickup_number?: string
  pickup_ready_date: string
  supplier_uuid?: string
}

export type FulfillmentHandlingUnitOrderItem = Pick<
  OrderItem,
  | 'commodity'
  | 'custom_package_type'
  | 'item_description'
  | 'item_number'
  | 'line_number'
  | 'max_temperature_uom'
  | 'max_temperature'
  | 'package_count'
  | 'package_type'
  | 'total_weight'
  | 'uuid'
> & {
  order: Pick<
    Order,
    | 'external_id'
    | 'po_number'
    | 'primary_ref'
    | 'so_number'
    | 'status'
    | 'uuid'
  >
  shipped_package_count: number
  shipped_package_weight: number
}

export type FulfillmentHandlingUnit = {
  uuid: string
  package_count: NonNullable<Measurable['package_count']>
  package_height: NonNullable<Measurable['height']>
  package_length: NonNullable<Measurable['length']>
  package_type: PackageType | null | ''
  package_width: NonNullable<Measurable['width']>
  stackable: NonNullable<Measurable['stackable']>
  turnable: NonNullable<Measurable['turnable']>
  order_items: FulfillmentHandlingUnitOrderItem[]
}

export type FulfillmentPickupAppointment = StopAppointment & {
  stop: {
    date: string
  }
}

export interface Fulfillment extends Omit<BaseFulfillment, 'handling_units'> {
  created_at: string
  created_by_name: string
  customer: {
    uuid: string
    name: string
  }
  handling_units: FulfillmentHandlingUnit[]
  order_uuids: string[]
  ref_number: string
  shipment_uuid?: string
  status: FulfillmentStatus
  supplier: {
    uuid: string
    name: string
  }
  uuid: string
  pickup_contact?: {
    uuid: string
    name: string
    email: string
    phone: string
    extension: string
  }
  pickup_facility: {
    uuid: string
    name: string
    address: string
    city: string
    state: string
    zipcode: string
    country: string
    latitude: string | null
    longitude: string | null
    warehouse_uuid: string | null
  }
  appointment?: FulfillmentPickupAppointment
  delivery_contact?: {
    uuid: string
    name: string
    email: string
    phone: string
    extension: string
  }
  delivery_facility: {
    uuid: string
    name: string
    address: string
    city: string
    state: string
    zipcode: string
    country: string
    latitude: string | null
    longitude: string | null
  }
}

export type FulfillmentForm = BaseFulfillment & {
  owner: 'us' | 'customer'
  order_identifier_type: 'po_number' | 'so_number'
  po_numbers: string
  so_numbers: string
  stops: Stop[]
}

export type TransientFulfillment = TransientDeep<FulfillmentForm>

export function createTransientFulfillment(): TransientFulfillment {
  return createTransient({
    owner: 'us',
    order_identifier_type: 'po_number',
    po_numbers: '',
    so_numbers: '',
    pickup_facility_uuid: '',
    pickup_instructions: '',
    delivery_instructions: '',
    pickup_number: '',
    pickup_ready_date: '',
    delivery_facility_uuid: '',
    delivery_date: '',
    handling_units: [],
    stops: [
      createTransientStop({ stop_type: 'pickup' }),
      createTransientStop({ stop_type: 'delivery' }),
    ],
  })
}
