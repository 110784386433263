import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import type { URLSearchParamsInit } from 'utils/searchParams'
import { createSearchParams } from 'utils/searchParams'

/**
 * getSearchParamsForLocation creates a base searchParams from the current URL and some defaultParams
 * by adding any param in defaultParams that's not included already in the current URL.
 * @param locationSearch history.location.search
 * @param defaultSearchParams any default URLSearchParams
 * @returns URLSearchParams that's the merge of both arguments
 */
function getSearchParamsForLocation(
  locationSearch: string,
  defaultSearchParams: URLSearchParams
) {
  const searchParams = new URLSearchParams(locationSearch)

  defaultSearchParams.forEach((_value, key) => {
    if (!searchParams.has(key)) {
      defaultSearchParams.getAll(key).forEach((value) => {
        searchParams.append(key, value)
      })
    }
  })

  return searchParams
}

export default function useSearchParams(
  init?: URLSearchParamsInit
): [URLSearchParams, (init: URLSearchParamsInit) => void] {
  const history = useHistory()
  const location = useLocation()

  const defaultSearchParams = useMemo(() => {
    return createSearchParams(init)
  }, [init])

  const searchParams = useMemo(() => {
    return getSearchParamsForLocation(location.search, defaultSearchParams)
  }, [location.search, defaultSearchParams])

  const setSearchParams = useCallback(
    (newInit: URLSearchParamsInit) => {
      const newSearchParams = createSearchParams(newInit)

      history.push(`${history.location.pathname}?${newSearchParams.toString()}`)
    },
    [history]
  )

  return [searchParams, setSearchParams]
}
