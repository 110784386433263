import { IconClose } from '@loadsmart/icons'
import { Button, Layout, Text } from '@loadsmart/miranda-react'

import type { SelectedTagsProps } from 'components/SelectedTags'
import SelectedTags from 'components/SelectedTags'

interface AppliedFiltersProps<T> {
  readonly appliedFilters: SelectedTagsProps<T>['selection']
  readonly handleRemove: (value: T) => void
  readonly onClearFilters: () => void
}

export const getLabel = <T,>(
  filterItems: T[] | undefined,
  getItemLabel: (item: T) => string
) => {
  if (!filterItems) {
    return ''
  }

  const selectedItems = filterItems.length
  if (selectedItems === 1) {
    return getItemLabel(filterItems[0])
  }

  return `${selectedItems} Selected`
}

const AppliedFilters = <T,>({
  appliedFilters,
  handleRemove,
  onClearFilters,
}: AppliedFiltersProps<T>) => {
  return (
    <Layout.Group align="center" data-testid="applied-filters">
      <Text variant="body-sm" color="color-text-tertiary">
        Applied filters:
      </Text>
      <SelectedTags
        selection={appliedFilters}
        onRemove={(value) => handleRemove(value)}
      />
      <Button
        size="small"
        variant="tertiary"
        leading={<IconClose width={12} height={12} />}
        onClick={() => onClearFilters()}
      >
        Clear filters
      </Button>
    </Layout.Group>
  )
}

export default AppliedFilters
