import {
  Button,
  DateRangePicker,
  Drawer,
  Select,
} from '@loadsmart/loadsmart-ui'
import type { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
import type EventLike from '@loadsmart/loadsmart-ui/dist/utils/types/EventLike'
import { Field, Layout } from '@loadsmart/miranda-react'
import type { FetchLocationOptions } from '@loadsmart/react-location-select'
import { useState } from 'react'

import { LocationSelect } from 'components/LocationSelect'
import { useSelectFacilitySearch } from 'hooks/useSelectFacilitySearch'
import { getLocationDisplayInformation } from 'screens/Quotes/List/utils'

import SelectedItemsTags from './SelectedItemsTags'

type DateRange = [string | number | null, string | number | null] | null
type StatusOptions = 'open' | 'planned' | 'planning' | 'deleted' | 'closed'

export interface Status {
  value: StatusOptions
  label: string
}

export const STATUS_SLUG_TO_LABEL: Record<StatusOptions, string> = {
  open: 'Open',
  planned: 'Planned',
  deleted: 'Deleted',
  planning: 'Planning',
  closed: 'Closed',
}

export const STATUSES: Array<Status> = Object.entries(STATUS_SLUG_TO_LABEL).map(
  ([value, label]) => ({
    value: value as StatusOptions,
    label,
  })
)

export interface Filters {
  statuses: Array<Status>
  pickupLocations: Array<LocationOption>
  pickupDateRange: DateRange
  deliveryLocations: Array<LocationOption>
  deliveryDateRange: DateRange
}

const newFilters = () => {
  return {
    statuses: [],
    pickupLocations: [],
    pickupDateRange: null,
    deliveryLocations: [],
    deliveryDateRange: null,
  }
}

const facilityDatasources = [useSelectFacilitySearch]

interface FiltersDrawerProps {
  readonly initialFilters: Filters
  readonly onApply: (nextValues: Filters) => void
  readonly onClose: () => void
  readonly onClickOutside: () => void
  readonly open: boolean
}

export function FiltersDrawer(props: FiltersDrawerProps) {
  const [filters, setFilters] = useState<Filters>(props.initialFilters)
  const locationConfig: FetchLocationOptions = {
    types: 'regions',
    fields: ['address_components', 'place_id'],
    restrictions: { country: ['us', 'ca', 'mx'] },
  }

  const unselectItem = (field: keyof Filters, itemIndex: number) => {
    if (!Array.isArray(filters[field])) {
      return
    }

    const updatedField = filters[field]?.filter(
      (_, valIndex) => valIndex !== itemIndex
    )
    setFilters((prevFormData) => ({
      ...prevFormData,
      [field]: updatedField,
    }))
  }

  const handleChange = (
    event:
      | EventLike<Selectable | Selectable[] | null>
      | EventLike<[string | null, string | null] | null>
  ) => {
    const { name, value } = event.target
    setFilters((prevFormData) => ({ ...prevFormData, [name]: value }))
  }

  const handleApplyClick = () => {
    props.onApply(filters)
  }

  const handleClearFiltersClick = () => {
    const clearFilters = newFilters()
    setFilters(clearFilters)
    props.onApply(clearFilters)
  }

  const getLocationOptionKey = (location: LocationOption): string => {
    if ('facility_uuid' in location && location.facility_uuid) {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string -- the type needs to be fixed
      return String(location.facility_uuid)
    }

    if ('place_id' in location && location.place_id) {
      return location.place_id
    }

    if ('uuid' in location && location.uuid) {
      return location.uuid
    }

    if ('warehouse_uuid' in location && location.warehouse_uuid) {
      return location.warehouse_uuid
    }

    return getLocationDisplayInformation(location)
  }

  return (
    <Drawer
      open={props.open}
      onClose={props.onClose}
      onClickOutside={props.onClose}
      data-testid="filters"
    >
      <Drawer.Header>Apply Filters</Drawer.Header>
      <Drawer.Body>
        <Layout.Stack>
          <Layout.Stack gap="spacing-2">
            <Field>
              <Field.Label id="status">Status</Field.Label>
              <Select
                multiple
                options={STATUSES}
                name="statuses"
                id="statuses"
                placeholder="Select status"
                disabled={false}
                onChange={handleChange}
                value={filters.statuses as Selectable}
              />
              <SelectedItemsTags
                items={filters.statuses}
                getItemKey={(item: Status) => item.value}
                onRemoveItem={(index) => unselectItem('statuses', index)}
                getLabel={(item) => item.label}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-2">
            <Field>
              <Field.Label id="pickup">Pickup Location</Field.Label>
              <LocationSelect
                multiple
                id="pickupLocations"
                name="pickupLocations"
                placeholder="Select pickup addresses, zipcode or facility name"
                value={filters?.pickupLocations}
                datasources={facilityDatasources}
                config={locationConfig}
                onChange={handleChange}
              />
              <SelectedItemsTags
                items={filters.pickupLocations}
                getItemKey={getLocationOptionKey}
                onRemoveItem={(index) => unselectItem('pickupLocations', index)}
                getLabel={(location) => getLocationDisplayInformation(location)}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1">
            <Field>
              <Field.Label id="pickupDateRange">Pickup date range</Field.Label>
              <DateRangePicker
                id="pickupDateRange"
                name="pickupDateRange"
                value={filters.pickupDateRange}
                onChange={handleChange}
                getRangeStartInputProps={() => ({
                  'aria-label': 'Select the start date of the range',
                })}
                getRangeEndInputProps={() => ({
                  'aria-label': 'Select the end date of the range',
                })}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-2">
            <Field>
              <Field.Label id="deliveryLocations">
                Delivery Location
              </Field.Label>
              <LocationSelect
                multiple
                id="deliveryLocations"
                name="deliveryLocations"
                placeholder="Select delivery addresses, zipcode or facility name"
                value={filters?.deliveryLocations}
                datasources={facilityDatasources}
                config={locationConfig}
                onChange={handleChange}
              />
              <SelectedItemsTags
                getItemKey={getLocationOptionKey}
                items={filters.deliveryLocations}
                onRemoveItem={(index) =>
                  unselectItem('deliveryLocations', index)
                }
                getLabel={(location) => getLocationDisplayInformation(location)}
              />
            </Field>
          </Layout.Stack>

          <Layout.Stack gap="spacing-1">
            <Field>
              <Field.Label id="deliveryDateRange">
                Delivery date range
              </Field.Label>
              <DateRangePicker
                id="deliveryDateRange"
                name="deliveryDateRange"
                value={filters.deliveryDateRange}
                onChange={handleChange}
                getRangeStartInputProps={() => ({
                  'aria-label': 'Select the start date of the range',
                })}
                getRangeEndInputProps={() => ({
                  'aria-label': 'Select the end date of the range',
                })}
              />
            </Field>
          </Layout.Stack>
        </Layout.Stack>
      </Drawer.Body>
      <Drawer.Footer>
        <Layout.Group justify="space-between" style={{ width: '100%' }}>
          <Layout.Group gap="spacing-4">
            <Button type="button" variant="primary" onClick={handleApplyClick}>
              Apply Filters
            </Button>
            <Button type="button" onClick={props.onClose}>
              Close
            </Button>
          </Layout.Group>
          <Button
            type="button"
            variant="tertiary"
            onClick={handleClearFiltersClick}
          >
            Clear Filters
          </Button>
        </Layout.Group>
      </Drawer.Footer>
    </Drawer>
  )
}
