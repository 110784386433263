import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'
import { capitalize, defaultTo, isEmpty, uniqBy } from 'lodash'

import type { PackageType } from 'components/ShippingItemsManager'
import { formatWeight } from 'components/ShippingItemsManager'
import type { TruckMileageStop } from 'orders/order-service'
import type { OrderItem } from 'orders/types'
import { PACKAGE_TYPE_LABELS } from 'screens/NewQuote/LTLQuote/LTLQuote.constants'
import { getFormattedPackageType } from 'screens/Orders/common/utils'
import { formatDate } from 'utils/dateUtils'
import { formatPhoneNumber } from 'utils/phone'
import { defaultEmptyString, plural } from 'utils/strings'

import type { FulfillmentDetailsHandlingUnit } from './details/ViewFulfillmentPage.data'
import type { Fulfillment, FulfillmentHandlingUnit } from './domain/Fulfillment'

export function formatFulfillmentStop(
  stop: Fulfillment['pickup_facility'] | Fulfillment['delivery_facility']
) {
  return `${capitalize(stop.city)}, ${stop.state} ${stop.zipcode}`
}

export function formatFulfillmentPickupReadyDate(
  fulfillment: Fulfillment,
  dateFormat = 'ddd, MMM D'
) {
  const dateFormatter = DateFormatHelper(dateFormat)

  return `${dateFormatter.format(DateHelper(fulfillment.pickup_ready_date))}`
}

export function formatFulfillmentDeliveryDate(
  fulfillment: Fulfillment,
  dateFormat = 'ddd, MMM D'
) {
  const dateFormatter = DateFormatHelper(dateFormat)

  return `${dateFormatter.format(DateHelper(fulfillment.delivery_date))}`
}

export function formatFulfillmentDates(
  fulfillment: Fulfillment,
  dateFormat = 'MMM D'
) {
  const dateFormatter = DateFormatHelper(dateFormat)

  return `${dateFormatter.format(DateHelper(fulfillment.pickup_ready_date))} → ${dateFormatter.format(DateHelper(fulfillment.delivery_date))}`
}

export function mapFulfillmentStopsToTruckMileageStops(
  fulfillment?: Fulfillment
): TruckMileageStop[] {
  const pickup = fulfillment?.pickup_facility
  const delivery = fulfillment?.delivery_facility

  if (
    !isEmpty(pickup?.latitude) &&
    !isEmpty(pickup?.longitude) &&
    !isEmpty(delivery?.latitude) &&
    !isEmpty(delivery?.longitude)
  ) {
    return [
      {
        latitude: Number(pickup?.latitude),
        longitude: Number(pickup?.longitude),
      },
      {
        latitude: Number(delivery?.latitude),
        longitude: Number(delivery?.longitude),
      },
    ]
  }

  if (!isEmpty(pickup?.zipcode) && !isEmpty(delivery?.zipcode)) {
    return [
      {
        postal_code: pickup?.zipcode,
      },
      {
        postal_code: delivery?.zipcode,
      },
    ]
  }

  return []
}

export function formatFulfillmentSource(fulfillment: Fulfillment) {
  return `Created by ${fulfillment.created_by_name} at ${formatDate(fulfillment.created_at, 'MM/DD/YYYY h:mm A')}`
}

/**
 * @param contact Pickup or delivery contact
 * @returns An array where each line contains a formatted component of the contact (name, phone, email). Blank components are removed.
 */
export function formatFulfillmentContact(
  contact?: Fulfillment['pickup_contact'] | Fulfillment['delivery_contact']
) {
  if (!contact) {
    return ['-']
  }

  let phoneNumber = formatPhoneNumber(contact.phone)

  if (contact.extension) {
    phoneNumber += ` EXT ${contact.extension}`
  }

  const lines: string[] = [contact.name, phoneNumber, contact.email]

  return lines.filter((line) => !isEmpty(line))
}

/**
 * @param fulfillment Fulfillment details
 * @returns Formatted weight. Returns - if total weight is null-ish or NaN.
 */
export function formatFulfillmentWeight(fulfillment?: Fulfillment) {
  const handlingUnits = defaultTo(fulfillment?.handling_units, [])
  const weight = handlingUnits.reduce(
    (sum, handlingUnit) =>
      sum + Number(getFulfillmentHandlingUnitShippedWeight(handlingUnit)),
    0
  )

  if (Number.isNaN(Number(weight))) {
    return '-'
  }

  return formatWeight(weight)
}

/**
 * @param packageCount Count
 * @param packageType Type
 * @returns Formatted packaging info using the default labels for PackageType
 */
export function formatFulfillmentHandlingUnitPackaging(
  packageCount: FulfillmentHandlingUnit['package_count'],
  packageType: FulfillmentHandlingUnit['package_type']
) {
  let formattedPackageType = ''
  const SHORT_PACKAGE_TYPE_LABELS = new Map<PackageType, string>(
    PACKAGE_TYPE_LABELS
  )
  SHORT_PACKAGE_TYPE_LABELS.set('std_pallets', 'Pallets')

  if (packageType) {
    const shortPackageType = SHORT_PACKAGE_TYPE_LABELS.get(packageType)
    if (shortPackageType !== undefined) {
      formattedPackageType = shortPackageType
    }
  } else {
    formattedPackageType = plural('Pallet', +packageCount)
  }

  return `${packageCount} ${formattedPackageType.toLocaleLowerCase()}`
}

/**
 * @param handlingUnit Fulfillment Handling Unit
 * @returns The summary/title of the handling unit, using its packaging info and its items' commodities
 */
export function formatFulfillmentHandlingUnitTitle(
  handlingUnit: FulfillmentHandlingUnit
) {
  const handlingUnitPackaging = formatFulfillmentHandlingUnitPackaging(
    handlingUnit.package_count,
    handlingUnit.package_type
  )
  const commodities = handlingUnit.order_items
    .map((item) => item.commodity)
    .filter((commodity) => !isEmpty(commodity))

  if (!commodities.length) {
    return handlingUnitPackaging
  }

  if (commodities.length === 1) {
    return `${handlingUnitPackaging} of ${commodities[0]}`
  }

  return `${handlingUnitPackaging} of ${commodities[0]}+${handlingUnit.order_items.length - 1}`
}

/**
 * @param params Commodity, ordered count, shipped count, package type, and custom package type
 * @returns A formatted string that expresses how much of the ordered quantity is being fulfilled
 */
export function formatFulfilledCommodity({
  commodity,
  customPackageType,
  orderedCount,
  packageType,
  shippedCount,
}: {
  commodity: OrderItem['commodity']
  customPackageType?: OrderItem['custom_package_type']
  orderedCount: number
  packageType: OrderItem['package_type']
  shippedCount: number
}) {
  const formattedPackageType = getFormattedPackageType(
    packageType,
    customPackageType,
    shippedCount
  )

  if (isEmpty(commodity)) {
    return `${shippedCount} of ${orderedCount} ${formattedPackageType.toLocaleLowerCase()}`
  }

  return `${shippedCount} of ${orderedCount} ${formattedPackageType.toLocaleLowerCase()} of ${commodity}`
}

export function formatHandlingUnitPONumbers(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  return defaultEmptyString(
    handlingUnit.poNumbers.toSorted((a, b) => a.localeCompare(b)).join(', ')
  )
}

export function formatHandlingUnitSONumbers(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  return defaultEmptyString(
    handlingUnit.soNumbers.toSorted((a, b) => a.localeCompare(b)).join(', ')
  )
}

export function formatHandlingUnitTotalShippedWeight(
  handlingUnit: FulfillmentDetailsHandlingUnit
) {
  return defaultEmptyString(
    formatWeight(defaultTo(handlingUnit.totalShippedWeight, ''))
  )
}

/**
 * @param fulfillment Fulfillment details
 * @returns Sum of the package_count of all the handling units inside the fulfillment
 */
export function getFulfillmentHandlingUnitsTotalPackageCount(
  fulfillment?: Fulfillment
) {
  const handlingUnits = defaultTo(fulfillment?.handling_units, [])

  const count = handlingUnits.reduce(
    (sum, handlingUnit) =>
      sum + Number(defaultTo(handlingUnit.package_count, 0)),
    0
  )

  return count
}

/**
 * @param handlingUnit Fulfillment Handling Unit
 * @returns Array with one entry per unique order among the order items included in the handling unit, sorted by Primary Ref
 */
export function getFulfillmentHandlingUnitUniqueOrders(
  handlingUnit?: FulfillmentHandlingUnit
) {
  const orders = defaultTo(
    handlingUnit?.order_items?.map((item) => item.order),
    []
  )

  return uniqBy(orders, (order) => order.uuid).sort((a, b) =>
    a.primary_ref.localeCompare(b.primary_ref)
  )
}

/**
 * @param handlingUnit Fulfillment Handling Unit
 * @returns Sum of the order items' shipped weight; null, if no items
 */
export function getFulfillmentHandlingUnitShippedWeight(
  handlingUnit?: FulfillmentHandlingUnit
) {
  const orderItems = handlingUnit?.order_items

  if (!orderItems?.length) {
    return null
  }

  const totalWeight = orderItems.reduce(
    (sum, item) => sum + Number(item.shipped_package_weight),
    0
  )

  return totalWeight
}
