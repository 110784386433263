import { identity } from '@loadsmart/utils-function'
import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useDebouncedCallback } from 'use-debounce'

import useSearchParams from 'hooks/useSearchParams'
import { deleteOrder, listOrders } from 'orders/order-service'
import type { ListOrder } from 'orders/types'
import type { LocationParam } from 'screens/Quotes/List/types'
import { encodeLocationParam } from 'screens/Quotes/List/utils'

import { ALLOWED_STATUSES_FOR_CONSOLIDATION } from '../common/constants'
import type { OrdersListFiltersParams } from './Filters'
import {
  SELECTED_ORDERS_QUERY_PARAM,
  useSelectedOrdersQueryParams,
} from './useSelectedOrders'

export interface UseOrdersListParams {
  limit: number
  offset: number
  search_term: string | null
  sort?: string
  filters: OrdersListFiltersParams
}

export function useOrdersList(
  { filters, ...params }: UseOrdersListParams,
  enabled?: boolean
) {
  const filterParams = {
    ...filters,
    delivery_location: !isEmpty(filters.delivery_location)
      ? encodeLocationParam(filters.delivery_location as LocationParam[])
      : null,
    pickup_location: !isEmpty(filters.pickup_location)
      ? encodeLocationParam(filters.pickup_location as LocationParam[])
      : null,
  }

  return useQuery({
    enabled,
    queryKey: ['listOrders', params, filterParams],
    queryFn: () =>
      listOrders({
        ...params,
        ...filterParams,
        uuid: filterParams.uuid ? filterParams.uuid.join(',') : null,
      }),
    refetchOnWindowFocus: false,
  })
}

const PAGE_SIZE = 50

export function useSearchParamsPagination() {
  const [searchParams, setSearchParams] = useSearchParams()
  const page = Number(searchParams.get('page')) || 1
  const perPage = Number(searchParams.get('per_page')) || PAGE_SIZE

  function onChange(event: { page: number; pageSize: number }) {
    searchParams.set('page', String(event.page + 1))
    searchParams.set('per_page', String(event.pageSize))

    setSearchParams(searchParams)
  }

  return {
    page,
    perPage,
    onChange,
  }
}

interface UseSearchParamsSearchTerm {
  onValueChanged: (value: string) => void
}

const MIN_SEARCH_TERM_LENGTH = 3

export function useSearchParamsSearchTerm(props: UseSearchParamsSearchTerm) {
  const [searchParams, setSearchParams] = useSearchParams()
  const value = searchParams.get('search_term') || ''

  const onChange = useDebouncedCallback((nextValue: string) => {
    searchParams.delete('search_term')

    if (nextValue.length >= MIN_SEARCH_TERM_LENGTH) {
      searchParams.set('search_term', nextValue)
      props.onValueChanged?.(nextValue)
    }

    setSearchParams(searchParams)
  }, 450)

  return {
    value: value.length >= MIN_SEARCH_TERM_LENGTH ? value : '',
    onChange,
  }
}

export interface UseDeleteOrderParams {
  onStart?: (order: ListOrder) => void
  onSuccess?: (order: ListOrder) => void
}

export function useDeleteOrder(params: UseDeleteOrderParams) {
  const [selectedOrder, setSelectedOrder] = useState<ListOrder>()

  const deleteOrderMutation = useMutation({
    mutationFn: deleteOrder,
    onSuccess() {
      toast.success('Order successfully deleted.')
      params.onSuccess?.(selectedOrder as ListOrder)
    },
    onError(error) {
      Sentry.captureException(error)
      toast.error('Order could not be deleted.')
    },
    onSettled() {
      setSelectedOrder(undefined)
    },
  })

  return {
    started: Boolean(selectedOrder),
    start(order: ListOrder) {
      setSelectedOrder(order)
      params.onStart?.(order)
    },
    cancel() {
      setSelectedOrder(undefined)
    },
    confirm() {
      if (!selectedOrder) {
        return
      }

      deleteOrderMutation.mutate(selectedOrder.uuid)
    },
    deleting: deleteOrderMutation.isLoading,
  }
}

function paramIsMulti(type: string, val?: unknown): val is unknown[] {
  return type === 'multi'
}

function isEmptyParamValue(val: unknown) {
  return isEmpty(JSON.parse(JSON.stringify(val)))
}

type ParamFilter<
  T extends Record<string, unknown>,
  K extends keyof T = string,
> = {
  key: K
  encode?: (value: unknown) => string
} & (
  | {
      type: 'single'
      decode?: (value: string | null) => T[K]
    }
  | {
      type: 'multi'
      decode?: (value: string[] | null) => T[K]
    }
)

export function useSearchParamsFilters<T extends Record<string, unknown>>(
  filters: ParamFilter<T>[]
) {
  const [searchParams, setSearchParams] = useSearchParams()

  const values = filters.reduce<T>((acc, paramFilter) => {
    if (paramFilter.type === 'multi') {
      const decode = paramFilter.decode ?? identity

      return {
        ...acc,
        [paramFilter.key]: decode(searchParams.getAll(paramFilter.key)),
      }
    }

    const decode = paramFilter.decode ?? identity

    return {
      ...acc,
      [paramFilter.key]: decode(searchParams.get(paramFilter.key)),
    }
  }, {} as T)

  function onChange(nextValues: Partial<T>) {
    filters.forEach((filter) => {
      searchParams.delete(filter.key)
    })

    Object.entries(nextValues).forEach(([key, val]) => {
      if (isEmptyParamValue(val)) {
        return
      }

      const { encode = String, type } = filters.find(
        (filter) => filter.key === key
      )!

      if (paramIsMulti(type, val)) {
        val.forEach((eachVal) => searchParams.append(key, encode(eachVal)))
      } else {
        searchParams.set(key, encode(val))
      }
    })

    setSearchParams(searchParams)
  }

  return {
    onChange,
    values,
  }
}

function useValidateOrderSelectionForConsolidation(orderUUIDs?: string[]) {
  const { refetch: fetchOrderSelectionData } = useOrdersList(
    {
      limit: 1000,
      offset: 0,
      search_term: null,
      filters: {
        status: null,
        pickup_location: null,
        delivery_location: null,
        pickup_date_after: null,
        pickup_date_before: null,
        delivery_date_after: null,
        delivery_date_before: null,
        uuid: orderUUIDs,
      },
    },
    false
  )

  return {
    fetchOrderSelectionData,
  }
}

export function useOrderSelectionForConsolidation() {
  const { selectedOrderUUIDs, setSelectedOrders } =
    useSelectedOrdersQueryParams()
  const { fetchOrderSelectionData } =
    useValidateOrderSelectionForConsolidation(selectedOrderUUIDs)
  const [validOrders, setValidOrders] = useState<ListOrder[]>([])

  const validate = useCallback(() => {
    return fetchOrderSelectionData().then(
      ({ data, error, isError, isLoading, isSuccess }) => {
        if (isError) {
          Sentry.captureException(error)
          toast.error('Unable to validate selected orders. Please try again.')

          return { isValid: false, isError }
        }

        if (!isLoading && isSuccess && data?.results?.length > 0) {
          const newValidOrders = data.results.filter((o) =>
            ALLOWED_STATUSES_FOR_CONSOLIDATION.includes(o.status)
          )

          setValidOrders(newValidOrders)

          return {
            isValid: newValidOrders.length === data.results.length,
            isError,
          }
        }

        return { isValid: false, isError }
      }
    )
  }, [fetchOrderSelectionData])

  const validOrdersQueryParams = useMemo(() => {
    const validOrdersParams = new URLSearchParams()
    validOrders.forEach((o) => {
      validOrdersParams.append(SELECTED_ORDERS_QUERY_PARAM, o.uuid)
    })

    return validOrdersParams
  }, [validOrders])

  return {
    selectedOrderUUIDs,
    setSelectedOrders,
    validOrders,
    validOrdersQueryParams,
    validate,
  }
}
