import type { LocationParam, Quote, QuoteTableFilters } from './types'

export function decodeLocationParam(param: string | null): LocationParam[] {
  if (param === null) {
    return []
  }

  return JSON.parse(atob(param))
}

/* The backend expects this param to be an array encoded in base64. We also escape any character that is not compatible with URLs like `=` */
export function encodeLocationParam(param: unknown): string {
  return btoa(JSON.stringify(param))
}

/**
 * We use this function to format a location for display purposes. it can work with the format of the LocationSelect component and the format that the backend expects
 */
export function getLocationDisplayInformation(
  location: LocationParam | LocationOption
) {
  if ('facility_uuid' in location) {
    return `${location.facility_name}`
  }

  if ('uuid' in location) {
    return `${location.company_name}`
  }

  return `${location.city}, ${location.state}, ${location.country}`
}

/**
 *
 * formatLocationForSelect changes the location object to a format that the LocationSelect component can understand and work with
 *
 * @param location The location object that is stored in the URL (It is normally encoded using the encodeLocationParam function above )
 * @returns The location object that the LocationSelect component expects
 */
export function formatLocationForSelect(
  location: LocationParam
): LocationOption {
  if ('facility_uuid' in location) {
    return {
      uuid: location.facility_uuid,
      company_name: location.facility_name,
      value: location.facility_uuid,
      _type: 'facility',
    } as LocationOptionForFacility
  }

  return {
    ...location,
    address: getLocationDisplayInformation(location),
    _type: 'gmaps-location',
  } as LocationOptionForAddress
}

/**
 *
 * formatLocationForURL changes the location object to a more simplified structure that is expected by the backend
 *
 * @param location The location object that the LocationSelect component uses
 * @returns The location object that should be stored in the URL and passed to the backend
 */
export function formatLocationForURL(location: LocationOption): LocationParam {
  if ('isFacility' in location) {
    return {
      facility_uuid: location.uuid,
      facility_name: location.company_name,
    }
  }
  return {
    city: location.city,
    state: location.state,
    country: location.country,
    zipcode: location.zipcode,
    place_id: location.place_id,
  }
}

export function getTagVariant(status: Quote['status']) {
  switch (status.value.toLowerCase()) {
    case 'expired':
    case 'tender_rejected':
      return 'warning'
    default:
      return 'default'
  }
}

export const isThereAtLeastOneFilter = (filters: QuoteTableFilters) => {
  return Object.values(filters).some((filter) => {
    if (filter === null) {
      return false
    }

    if (Array.isArray(filter)) {
      return filter.length > 0
    }

    return true
  })
}

export function adaptDrayageStops(quotes: Quote[]) {
  return quotes.map((quote) => {
    if (quote.mode.toLowerCase() === 'drayage') {
      const [pickup, delivery] = quote.stops

      return {
        ...quote,
        stops: [{ ...pickup }, { ...delivery }],
      }
    }

    return quote
  })
}
