import { Tag } from '@loadsmart/loadsmart-ui'
import { Layout } from '@loadsmart/miranda-react'

type RemovableTagsProps<T> = {
  readonly items: T[]
  readonly getItemKey: (item: T) => string
  readonly onRemoveItem: (index: number) => void
  readonly getLabel: (item: T) => string
}

export default function SelectedItemsTags<T>(props: RemovableTagsProps<T>) {
  if (!Array.isArray(props.items)) {
    return null
  }
  return (
    <Layout.Group gap="spacing-2">
      {props.items.map((item, index) => (
        <Tag
          key={props.getItemKey(item)}
          data-testid={`selected-item-tag-${props.getItemKey(item)}`}
          variant="default"
          removable
          onRemove={() => props.onRemoveItem(index)}
        >
          {props.getLabel(item)}
        </Tag>
      ))}
    </Layout.Group>
  )
}
