import type { AxiosRequestConfig } from 'axios'
import type { Supplier } from 'suppliers/types'

import httpClient from 'utils/httpClient'

import type { Customer } from './domain/Customer'
import type { BaseFulfillment, Fulfillment } from './domain/Fulfillment'

export async function getFulfillmentDetails(id: string): Promise<Fulfillment> {
  const { data } = await httpClient.get(`/orders/fulfillments/${id}`)
  return data
}

export async function getSuppliers(): Promise<Supplier[]> {
  const { data } = await httpClient.get(`/shippers/suppliers/list`)
  return data
}

export async function getCustomers(): Promise<Customer[]> {
  const { data } = await httpClient.get(`/shippers/customers/list`)
  return data
}

export type CreateFulfillmentParams = {
  payload: BaseFulfillment
  config?: AxiosRequestConfig
}
export function createFulfillment({
  payload,
  config,
}: CreateFulfillmentParams) {
  return httpClient.post('/orders/fulfillments/without-order', payload, config)
}
