import { useQuery } from 'react-query'

import { validateOrdersForConsolidation } from 'orders/order-service'

export const useValidateOrders = (
  orderUUIDs: string[],
  enabled: boolean = true
) => {
  return useQuery({
    enabled: orderUUIDs.length > 0 && enabled,
    queryKey: ['validateOrdersForConsolidation', { orderUUIDs }],
    queryFn: ({ signal }) =>
      validateOrdersForConsolidation({ orderUUIDs }, { signal }),
    refetchOnWindowFocus: false,
  })
}
