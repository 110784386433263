import { DateFormatHelper, DateHelper } from '@loadsmart/loadsmart-ui'

import type { Order } from 'orders/types'
import type { LocationParam } from 'screens/Quotes/List/types'
import {
  formatLocationForSelect,
  formatLocationForURL,
  getLocationDisplayInformation,
} from 'screens/Quotes/List/utils'

import { AppliedFilters } from './AppliedFilters'
import type { Filters, Status } from './FiltersDrawer'
import { FiltersDrawer, STATUS_SLUG_TO_LABEL, STATUSES } from './FiltersDrawer'

function isLocationOption(value: object): value is LocationOption {
  return '_type' in value
}

function formatLocationsForURL(values: LocationOption[] | undefined) {
  if (!values) {
    return null
  }

  return values.map((value) => {
    if (isLocationOption(value)) {
      if (value._type === 'facility') {
        return formatLocationForURL({ ...value, isFacility: true })
      }

      return formatLocationForURL(value)
    }

    return value
  })
}

const formatDateFilters = (date: string | null) => {
  return date ? DateFormatHelper('YYYY-MM-DD').format(DateHelper(date)) : null
}

export type OrdersListFiltersParams = {
  status: Order['status'][] | null
  delivery_location: LocationParam[] | null
  pickup_location: LocationParam[] | null
  pickup_date_after: string | null
  pickup_date_before: string | null
  delivery_date_after: string | null
  delivery_date_before: string | null
  uuid?: string[] | null
}

export interface OrdersFiltersDrawerProps {
  readonly control: { open: boolean; show: () => void; hide: () => void }
  readonly values: Omit<OrdersListFiltersParams, 'uuid'>
  readonly onChange: (nextValues: Omit<OrdersListFiltersParams, 'uuid'>) => void
}

export function OrdersFiltersDrawer(props: OrdersFiltersDrawerProps) {
  const mapStatusToStatuses = (
    status: Array<Order['status']>
  ): Array<Status> => {
    return STATUSES.filter((s) => status.indexOf(s.value) >= 0)
  }

  const valuesToInitialFilters = (
    values: Omit<OrdersListFiltersParams, 'uuid'>
  ): Filters => {
    return {
      statuses: mapStatusToStatuses(values.status || []),
      pickupLocations:
        values.pickup_location?.map(formatLocationForSelect) || [],
      deliveryLocations:
        values.delivery_location?.map(formatLocationForSelect) || [],
      pickupDateRange: [values.pickup_date_after, values.pickup_date_before],
      deliveryDateRange: [
        values.delivery_date_after,
        values.delivery_date_before,
      ],
    }
  }

  const onApply = (appliedFilters: Filters) => {
    const { pickupLocations, deliveryLocations } = appliedFilters

    const status = appliedFilters.statuses.map((s) => s.value)
    const pickup_date = appliedFilters.pickupDateRange?.map((pdr) =>
      pdr != null ? String(pdr) : null
    )
    const delivery_date = appliedFilters.deliveryDateRange?.map((ddr) =>
      ddr != null ? String(ddr) : null
    )

    props.onChange({
      status,
      pickup_location: formatLocationsForURL(pickupLocations),
      delivery_location: formatLocationsForURL(deliveryLocations),
      pickup_date_after: formatDateFilters(pickup_date?.[0] || null),
      pickup_date_before: formatDateFilters(pickup_date?.[1] || null),
      delivery_date_after: formatDateFilters(delivery_date?.[0] || null),
      delivery_date_before: formatDateFilters(delivery_date?.[1] || null),
    })
    props.control.hide()
  }

  return props.control.open ? (
    <FiltersDrawer
      open={props.control.open}
      onClose={props.control.hide}
      onClickOutside={props.control.hide}
      initialFilters={valuesToInitialFilters(props.values)}
      onApply={onApply}
    />
  ) : null
}

export interface OrdersAppliedFiltersProps {
  readonly values: Omit<OrdersListFiltersParams, 'uuid'>
  readonly onChange: (
    nextValues: Partial<Omit<OrdersListFiltersParams, 'uuid'>>
  ) => void
}

const getDateDisplayInformation = (date: string) => {
  return DateFormatHelper('MM/DD/YYYY').format(DateHelper(date))
}

export function OrdersAppliedFilters(props: OrdersAppliedFiltersProps) {
  return (
    <AppliedFilters
      values={props.values}
      onChange={props.onChange}
      labels={{
        status: 'Status',
        delivery_location: 'Delivery',
        pickup_location: 'Pickup',
        pickup_date_after: 'Pickup date after',
        pickup_date_before: 'Pickup date before',
        delivery_date_after: 'Delivery date after',
        delivery_date_before: 'Delivery date before',
      }}
      valueFormatter={{
        status: (option) => STATUS_SLUG_TO_LABEL[option],
        delivery_location: getLocationDisplayInformation,
        pickup_location: getLocationDisplayInformation,
        pickup_date_after: getDateDisplayInformation,
        delivery_date_after: getDateDisplayInformation,
        pickup_date_before: getDateDisplayInformation,
        delivery_date_before: getDateDisplayInformation,
      }}
    />
  )
}

export { STATUS_SLUG_TO_LABEL }
