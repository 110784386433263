import { Tag } from '@loadsmart/loadsmart-ui'

export interface SelectedTagsProps<T> {
  readonly selection: { value: T; label: string }[]
  readonly onRemove: (value: T) => void
}

function SelectedTags<T>({ selection, onRemove }: SelectedTagsProps<T>) {
  return (
    <>
      {selection.map(({ value, label }) => {
        return (
          <Tag
            size="default"
            variant="default"
            key={String(value)}
            removable
            onRemove={() => {
              onRemove(value)
            }}
            getRemoveButtonProps={() => ({
              'aria-label': `Remove ${label}`,
            })}
          >
            {label}
          </Tag>
        )
      })}
    </>
  )
}

export default SelectedTags
